import React, { Fragment, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import classNames from "classnames"

import Container from "../../../Layout/Container"

import { ProgramName, Brand } from "../../../Elements/Brand"
import { animate } from "../../../../services/animations"
import styles from "../../utils/staticPages.modules.scss"

const HomeBanner = () => {
  const data = useStaticQuery(graphql`
    {
      stethoscope: file(relativePath: { eq: "stethoscope.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 2000
            duotone: { highlight: "#ffffff", shadow: "#3d8b8b" }
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const stethoscope = data.stethoscope.childImageSharp.fluid

  useEffect(() => {
    animate({ target: ".fade-to-right", x: -50 })
  }, [])

  return (
    <Fragment>
      <section className="hero is-fullheight">
        <div
          className="hero-body"
          style={{
            backgroundImage: `url(${stethoscope.src})`,
            backgroundPosition: "cover",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Container isCentered desktop={10} fullhd={8}>
            <div className="container fade-to-right">
              {" "}
              <h2>
                Thank you for participating in the HOPE Program for Afatinib
                (Giotrif).
              </h2>
              <div className={classNames("is-flex mt-2")}>
                <section className="is-size-5">
                  The Afatinib (Giotrif) medicine assistance program officially
                  ended on December 31, 2022. Thank you for letting us serve
                  you.
                </section>
              </div>
              <div className={classNames("is-flex mt-2")}>
                <section className="is-size-5">
                  For any questions, you can reach out directly to Boehringer
                  Ingelheim Philippines, Inc. at{" "}
                  <a
                    href="mailto:BI.SKIES.ph@boehringer-ingelheim.com"
                    className="has-text-weight-bold"
                  >
                    BI.SKIES.ph@boehringer-ingelheim.com
                  </a>
                </section>
              </div>
            </div>
          </Container>
        </div>
      </section>
    </Fragment>
  )
}

export default HomeBanner
