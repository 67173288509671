import React from "react"
import { Router } from "@reach/router"

import Giotrif from "../components/StaticPages/Home/Giotrif/Home"

export default () => {
  return (
    <Router basepath="/giotrif">
      <Giotrif path="/" />
    </Router>
  )
}
